<template>
    <b-modal
        hide-footer
        :title="$t('global.information')"
        modal-class="modal-update"
        v-model="isModalOpen"
        lazy
        no-close-on-esc
        no-close-on-backdrop
    >
        {{ $t('global.licence_expire', {date: validity_date_formatted}) }}
        <b-button class="mt-3" block @click.prevent="close">{{ $t("global.close") }}</b-button>
    </b-modal>
</template>

<script>
export default {
    name: 'LicenceReminder',
    data: () => ({
        licence_expire: 'CHECKING',
        validity_date_formatted: null
    }),
    mounted() {
        this.init_component()
    },
    computed: {
        isModalOpen() {
            return this.licence_expire === 'COMING_SOON'
        }
    },
    methods: {
        async init_component() {
            if(!this.getConfig("licence_last_check_date")) {
                let check = new Date()
                check.setHours(0)
                check.setMinutes(0)
                check.setSeconds(0)
                check.setDate(check.getDate() - 1)

                await this.setConfig('licence_last_check_date', check)
            }

            let previous_check = new Date(this.getConfig("licence_last_check_date"))
            previous_check.setHours(0)
            previous_check.setMinutes(0)
            previous_check.setSeconds(0)

            let now = new Date()
            now.setHours(0)
            now.setMinutes(0)
            now.setSeconds(0)

            const validity_date = new Date(this.getConfig("licence_validity_date"))
            this.validity_date_formatted = validity_date.getDate() + '/' + parseInt(validity_date.getMonth()+1) + '/' + validity_date.getFullYear()

            let two_weeks = new Date(validity_date)
            two_weeks.setDate(two_weeks.getDate() - 15)

            if((now.getDate() !== previous_check.getDate() || now.getMonth() !== previous_check.getMonth() || now.getFullYear() !== previous_check.getFullYear()) && two_weeks <= now) {
                this.licence_expire = 'COMING_SOON'
                await this.setConfig('licence_last_check_date', now)
            }
        },
        close() {
            this.licence_expire = 'CLOSED'
        }
    }
}
</script>
